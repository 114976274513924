import * as React from "react";

import {Layout, MainLogo} from "../components/layout";
import Seo from "../components/seo";

const ApplicationsPage = () => {
  return (
    <Layout>
      <Seo title="Plant Army: Twitch Stream Team" />
      <div className="bg-gradient-to-br to-emerald-500 from-emerald-800 text-white">
        <div className="pt-20 pb-8 lg:pt-32 lg:pb-24 container max-w-3xl	mx-auto">
          <div className="flex flex-wrap lg:flex-nowrap justify-center justify-items-center content-center items-center">
            <div className="lg:flex-none">
              <MainLogo />
            </div>
            <div className="text-center lg:text-left lg:flex-1 lg:pl-12 p-6 max-w-lg">
              <h2 className="font-serif text-3xl lg:text-4xl font-semibold leading-none lg:leading-none pb-4">
                Team Applications
              </h2>
              <p className="text-sm lg:text-base">
                Open September 1st-3rd, 2022
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-w-lg mx-auto py-10">
        <h2 className="font-serif font-bold text-center text-xl lg:text-2xl mb-6">
          What you'll need:
        </h2>
        <ol className="list-decimal">
          <li>a 3-5 minute video so we can get to know your personality</li>
          <li>
            be prepared to fill out a Google Form talking about yourself, your
            content, and what you hope to bring to and see from the Plant Army
          </li>
        </ol>

        <h2 className="font-serif font-bold text-center text-xl lg:text-2xl mb-6 mt-6">
          Requirements:
        </h2>

        <ol className="list-decimal">
          <li>be 18+</li>
          <li>stream at least once per month</li>
          <li>have been streaming at least 4-6 months</li>
        </ol>

        <h2 className="font-serif font-bold text-center text-xl lg:text-2xl mb-6 mt-6">
          What to put in your video:
        </h2>

        <p className="mb-2">
          We recommend recording at least 1-2 minutes just introducing yourself
          and letting us know what you are most passionate about! You can fill
          the rest of the time - or all of it if you prefer - with the clips
          that best represent you and your streams.
        </p>

        <p className="mb-2">
          We encourage you to keep in mind our four pillars, which you can read
          more about on the home page, and think about how you can demonstrate
          those values in your video!
        </p>

        <p>
          It is okay to submit a video shorter than 5 minutes (3 minute
          minumum), but you will be{" "}
          <strong>
            automatically removed from consideration if you submit a video
            longer than 5 minutes.
          </strong>
        </p>

        <h2 className="font-serif font-bold text-center text-xl lg:text-2xl mb-6 mt-6">
          Where do I submit my application?
        </h2>

        <p>
          Keep an eye out for our{" "}
          <a
            href="https://www.twitter.com/plantarmy"
            target="_blank"
            className="text-blue-600"
            rel="noreferrer"
          >
            @plantarmy
          </a>{" "}
          Twitter announcements, which will include all the information you
          need. You can also bookmark this page, as it will update with the
          forms you need to submit.
        </p>

        <h2 className="font-serif font-bold text-center text-xl lg:text-2xl mb-6 mt-6">
          I have more questions!
        </h2>

        <p>
          Please check the home page for our FAQ section! If you still can't
          find what you're looking for, you can tag us on Twitter or shoot one
          of the team leaders an email (also found on the home page).
        </p>
      </div>
    </Layout>
  );
};

export default ApplicationsPage;
